<template>
  <div class="h-screen">
    <CleanHeader />
    <div class="min-h-[calc(100vh-160px)] flex items-center justify-center">
      <suspense>
        <slot />
      </suspense>
    </div>
    <Footer />
  </div>
</template>

<script setup type="ts">

const route = useRoute();
const { $getAbsoluteUrl } = useNuxtApp();

useHead(() => ({
  link: [
    {
      rel: "canonical",
      href: $getAbsoluteUrl() + route.path
    }
  ]
}));
</script>
