<template>
  <nav class="h-[60px] border-b border-[#E4F6F8] dark:border-cs-black">
    <div class="flex items-center justify-between px-[30px] h-full">
      <NuxtLink href="/">
        <CardanoSpotLogo class="dark:hidden" />
        <CardanoSpotLogoLight class="hidden dark:block" />
      </NuxtLink>
      <DarkThemeSwitcher />
    </div>
  </nav>
</template>

<script lang="ts" setup></script>
